<template>
  <div id="app" class="dark:bg-gray-800 text-black dark:text-white">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
};
</script>
